import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/enter'
  },
  {
    path:"/enter",
    name:"enter",
    component:()=>import("../views/Scan.vue"),
    meta:{
      metaInfo:{
        title:"二维码生成",
        keywords:"qrCode",
        description:"qrCode"
      }
    }
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
